import React from "react"
import cx from "classnames"

import Form from "../../form/form"

import styles from "./sidebarForm.module.scss"

const SidebarForm = ({ link, title, hideOnMobile, hideOnDesktop }) => {
  const hideClass = hideOnMobile ? styles.hideOnMobile : hideOnDesktop ? hideOnDesktop : null

  return (
    <div className={cx(styles.blogSubscribeForm, hideClass)}>
      <h3 className={styles.title}>{title}</h3>
      <Form title={title} background="blue" link={link} noPadding={true} noId />
    </div>
  )
}

export default SidebarForm
