import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo/seo"

import Layout from "../components/layout/layout"
import HeroSimple from "../components/hero/heroSimple/heroSimple"
import Wrapper from "../components/layout/wrapper/wrapper"
import BlockContent from "../components/blockContent/blockContent"
import SidebarForm from "../components/shared/sidebarForm/sidebarForm"
import Cta from "../components/cta/cta"
import CtaForm from "../components/ctaForm/ctaForm"

// todo
import styles from "./shared.module.scss"

const ResourceThankYou = ({ data }) => {
  const resource = data.sanityResourceThankYou

  return (
    <Layout>
      <Seo title={resource.title} />
      <HeroSimple blocks={resource.hero[0].children} subtitle={resource.subtitle} />
      <Wrapper noPadding>
        <div className={styles.inner}>
          {resource.form ? (
            <div className="grid">
              <div className="gridItem small-12 medium-3 medium-offset-1">
                <SidebarForm link={resource.form.link} title={resource.form.title} />
              </div>
              <div className="gridItem small-12 medium-6 medium-offset-5">
                <BlockContent blocks={resource.richText} />
              </div>
            </div>
          ) : (
            <div className="grid">
              <div className="gridItem small-12 medium-10 medium-offset-1">
                <BlockContent blocks={resource.richText} />
              </div>
            </div>
          )}
        </div>
      </Wrapper>
      {resource.cta &&
        (resource.cta?.__typename === "SanityCtaForm" ? (
          <CtaForm {...resource.cta} background="blue" />
        ) : (
          <Cta {...resource.cta} background="blue" />
        ))}
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    sanityResourceThankYou(slug: { current: { eq: $slug } }) {
      hero: _rawHero
      subtitle
      richText: _rawRichText(resolveReferences: { maxDepth: 5 })
      title
      subtitle
      slug {
        current
      }
      featuredImage {
        asset {
          fluid(maxWidth: 250) {
            ...GatsbySanityImageFluid
          }
        }
      }
      cta {
        ... on SanityCta {
          ...ctaFragment
        }
        ... on SanityCtaForm {
          ...ctaFormFragment
        }
      }
    }
  }
`

export default ResourceThankYou
