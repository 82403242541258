import React from "react"
import Reveal from "react-reveal/Reveal"

import Button from "../button/button"
import BlockContent from "../blockContent/blockContent"
import Wrapper from "../layout/wrapper/wrapper"

import styles from "./cta.module.scss"

// todo proptypes

const Cta = ({ title, text, _rawLinks, background }) => (
  <Wrapper background={background}>
    <div className="grid">
      <div className="gridItem small-12 medium-10 medium-offset-1">
        <div className={styles.cta}>
          <div className={styles.wrapper}>
            <div className={styles.title}>
              <BlockContent className={styles.title} blocks={title} />
            </div>
            <Reveal effect="fadeInDown">
              <p className={styles.text}>{text}</p>
              <div className={styles.buttons}>
                {_rawLinks?.map(link => (
                  <Button link={link.url} key={link._key} {...link}>
                    {link.label}
                  </Button>
                ))}
              </div>
            </Reveal>
          </div>
        </div>
      </div>
    </div>
  </Wrapper>
)

export default Cta
