import React from "react"
import Reveal from "react-reveal/Reveal"

import BlockContent from "../blockContent/blockContent"
import Wrapper from "../layout/wrapper/wrapper"
import Form from "../form/form"
import Img from "gatsby-image"

import styles from "./ctaForm.module.scss"

// todo proptypes

const CtaForm = ({ title, form, background, image }) => (
  <Wrapper background={background}>
    <div className="grid">
      <div className="gridItem small-12 medium-10 medium-offset-1">
        <div className={styles.ctaForm}>
          <div>
            <div className={styles.title}>
              <BlockContent blocks={title} />
            </div>
            <Form {...form} noPadding={true} />
          </div>
          <Reveal effect="fadeInDown">
            <div className={styles.imageWrapper}>
              <Img className={styles.image} fluid={image.asset.fluid} />
            </div>
          </Reveal>
        </div>
      </div>
    </div>
  </Wrapper>
)

export default CtaForm
